import { QueueUser } from '.';
import { ISelectableOption, SortByType, FilterType, GroupByType } from './filter.models';

export class FilterBarSelectedOptions {
    freeTextTerms: Array<string> = [];
    primarySortType: SortByType = null;
    secondarySortType: SortByType = null;
    primarySortDir: boolean;
    secondarySortDir: boolean;
    groupByType: GroupByType;
    showFutureTasks: boolean;
    impersonatedUser: QueueUser;
    minValue: number;
    maxValue: number;

    private options: Array<ISelectableOption> = [];
    constructor(options: Array<ISelectableOption>) {
        this.options = options;
    }

    getDocumentTypeOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.DOCUMENT);
    }

    getDocumentRequirementTypeOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.DOCUMENT_REQUIREMENT);
    }

    getOrderTypeOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.ORDER_TYPE);
    }
    getActionStageOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.ACTION_STAGE);
    }
    getSubOrderTypeOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.SUBORDER_TYPE);
    }

    getDueDateOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.DUE_DATE);
    }

    getPayerOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.PAYER);
    }

    getMirPayerOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.MIR_PAYER);
    }

    getMirTypeOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.MIR_TYPE);
    }

    getMirSubTypeOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.SUB_TYPE);
    }

    getRtsOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.RTS);
    }

    getFacilityOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.FACILITY);
    }

    getBranchOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.BRANCH);
    }

    getStateOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.STATE);
    }
    getNeedsReviewOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.NEEDS_REVIEW);
    }
    getPhysicianOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.PHYSICIAN);
    }

    getKeyAcctManagerNameOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.KEY_ACCOUNT_MANAGER);
    }

    getTherapistOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.THERAPIST);
    }

    getCustomerTypeOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.CUSTOMER_TYPE);
    }

    getClientZoneOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.CLIENT_ZONE);
    }

    getEvaluationZoneOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.EVAL_ZONE);
    }

    getDeliveryZoneOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.DELIVERY_ZONE);
    }

    getCsrOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.CSR);
    }

    getFreeTextOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.FREE);
    }

    getBillingOptions(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.BILLING);
    }

    getAmountUnbilled(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.AMOUNT_UNBILLED);
    }

    getRevenue(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.REVENUE);
    }

    getTimelyFilingDate(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.TIMELY_FILING_DATE);
    }

    getOrderTotalOption(): ISelectableOption {
        var o = this.getOptionsByType(FilterType.ORDER_TOTAL);

        if (o.length == 0) return null;
        else return o[0];
    }
    getExternalCandidate(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.EXTERNAL_CANDIDATE);
    }
    getRtrOrders(): Array<ISelectableOption> {
        return this.getOptionsByType(FilterType.RTR_ORDERS);
    }
    hasFiltersApplied(): boolean {
        return this.options.length > 0 || this.freeTextTerms.length > 0;
    }

    getOptionsByType(type: FilterType): Array<ISelectableOption> {
        return this.options.filter((option: ISelectableOption) => {
            return option.type === type;
        });
    }
}
