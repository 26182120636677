import { QueueListType } from '@app/core/models/queue.type';
import { GroupByType, ISelectableOption, SortByType } from './filter.models';

export class FilterPanelConfig {
    useRequirementTypes: boolean = false;
    useBranches: boolean = false;
    useOrderTypes: boolean = false;
    useSubOrderTypes: boolean = false;
    useRts: boolean = false;
    usePayers: boolean = false;
    usePayer1: boolean = false;
    useMirPayer: boolean = false;
    useMirType: boolean = false;
    useMirSubcategory: boolean = false;
    useFacilities: boolean = false;
    usePhysicians: boolean = false;
    useDocuments: boolean = false;
    useTherapists: boolean = false;
    useStates: boolean = false;
    useCustomerType: boolean = false;
    useClientZones: boolean = false;
    useDeliveryCity: boolean = false;
    useEvalCity: boolean = false;
    useClientCity: boolean = false;
    useEvalZones: boolean = false;
    useDeliveryZones: boolean = false;
    useCsr: boolean = false;
    useActionStage: boolean = false;
    useOrderTotal: boolean = false;
    defaultSelectedOptions: ISelectableOption[] = [];
    primarySortOptions: Array<SortByType> = new Array<SortByType>();
    secondarySortOptions: Array<SortByType> = new Array<SortByType>();
    groupingOptions: Array<GroupByType> = new Array<GroupByType>();
    allowEmptyGroupingOption: boolean = true;
    defaultGrouping: GroupByType;
    queueListType: QueueListType = QueueListType.FS;
    defaultFutureTasksOn: boolean = false;
    useKeyAcctManagerName: boolean = false;
    useNeedsReview: boolean = false;
    useBilling: boolean = false;
    useAmountUnbilled: boolean = false;
    useRevenue: boolean = false;
    useTimelyFilingDate: boolean = false;
    useWorkLocation: boolean = false;
    useRtrOrders: boolean = true;
}
