export interface ISelectableOption {
    text: string;
    value: string;
    count: number;
    type: FilterType;
    minValue?: number;
    maxValue?: number;
}
export interface IFilterTerm {
    text: string;
    // Which Filter the term came from
    filter: FilterType;
}

export enum FilterType {
    ORDER_TYPE = 0,
    PAYER = 1,
    RTS = 2,
    FACILITY = 3,
    BRANCH = 4,
    FREE = 5,
    PHYSICIAN = 6,
    THERAPIST = 7,
    DOCUMENT = 8,
    DOCUMENT_REQUIREMENT = 9,
    STATE = 10,
    CUSTOMER_TYPE = 11,
    DUE_DATE = 12,
    CLIENT_ZONE = 13,
    EVAL_ZONE = 14,
    DELIVERY_ZONE = 15,
    CSR = 16,
    MIR_PAYER = 17,
    SUBORDER_TYPE = 18,
    KEY_ACCOUNT_MANAGER = 19,
    ACTION_STAGE = 20,
    ORDER_TOTAL = 21,
    NEEDS_REVIEW = 22,
    SUB_TYPE = 23,
    MIR_TYPE = 24,
    BILLING = 25,
    AMOUNT_UNBILLED = 26,
    REVENUE = 27,
    TIMELY_FILING_DATE = 28,
    EXTERNAL_CANDIDATE = 29,
    RTR_ORDERS = 31
}

export const ConfigFilterTypes = [
    FilterType.ORDER_TYPE,
    FilterType.SUBORDER_TYPE,
    FilterType.PAYER,
    FilterType.RTS,
    FilterType.FACILITY,
    FilterType.BRANCH,
    FilterType.PHYSICIAN,
    FilterType.THERAPIST,
    FilterType.DOCUMENT,
    FilterType.DOCUMENT_REQUIREMENT,
    FilterType.STATE,
    FilterType.CUSTOMER_TYPE,
    FilterType.CLIENT_ZONE,
    FilterType.EVAL_ZONE,
    FilterType.DELIVERY_ZONE,
    FilterType.KEY_ACCOUNT_MANAGER,
    FilterType.ACTION_STAGE,
    FilterType.ORDER_TOTAL,
    FilterType.NEEDS_REVIEW,
    FilterType.SUB_TYPE,
    FilterType.MIR_TYPE,
    FilterType.BILLING,
    FilterType.AMOUNT_UNBILLED,
    FilterType.REVENUE,
    FilterType.TIMELY_FILING_DATE,
    FilterType.EXTERNAL_CANDIDATE,
    FilterType.RTR_ORDERS
];

export enum GroupByType {
    ORDER_TYPE = 'Order Type',
    SUBORDER_TYPE = 'Sub-Order Type',
    PAYER = 'Payer',
    RTS = 'RTS',
    FACILITY = 'Facility',
    BRANCH = 'Branch',
    PHYSICIAN = 'Physician',
    THERAPIST = 'Therapist',
    DOCUMENT = 'Document Type',
    CLIENT = 'Client',
    WORK_ORDER_NUMBER = 'Work Order Number',
    KEY_ACCOUNT_MANAGER = 'Key Account Manager',
    ACTION_STAGE = 'Action Stage'
}

export enum StateTypes {
    ALS = 'ALS',
    CREDIT_EXCEPTION = 'Credit Exception',
    CREDIT_EXCEPTION_DELIVERY = 'Credit Exception Delivery',
    EXPEDITED = 'Expedited',
    FFA = 'FFA',
    // FUTURE = 'Future',
    ON_HOLD = 'On Hold',
    PAST_DUE = 'Past Due',
    PRIORITY = 'Priority',
    EXPIRING_AUTH = 'Expiring Auth',
    EXPIRED_AUTH = 'Expired Auth',
    IS_BENEFITS_CHECKED = 'No Benefits'
}
export enum NeedsReviewType {
    NEEDS_REVIEW = 'Validation Required'
}
export enum SortByType {
    ACTION_STAGE = 'Action Stage',
    ORDER_TOTAL_AMOUNT = 'Expected Reimbursement',
    ALL_PO_ISSUED = 'All PO Issued',
    AMOUNT_UNBILLED = 'Amount Unbilled',
    ASSEMBLED_ON = 'Assembled',
    BENEFITS_CHECKED = 'Benefits Checked',
    BILLING_CONTACT = 'Billing Contact',
    BRANCH = 'Branch',
    BRANCH_RECVD = 'Branch Received',
    CLIENT = 'Client',
    CLIENT_CITY = 'Client City',
    CLIENT_ZIP = 'Client Zip',
    CLIENT_ZONE = 'Client Zone',
    DAYS_IN_REPORT = 'Days in Report',
    DEFERRED_DATE = 'Deferred Date',
    DELIVERY_ACTUAL_DATE = 'Actual Delivery Date',
    DELIVERY_ATTEMPTS = 'Delivery Attempts',
    DELIVERY_CITY = 'Delivery City',
    DELIVERY_LAST_ATTEMPT_DATE = 'Last Devliery Attempt',
    DELIVERY_PERSON = 'Delivery Person',
    DELIVERY_SCHEDULED_DATE = 'Scheduled Delivery Date',
    DELIVERY_TICKET_SCANNED = 'Delivery Ticket Scan',
    DELIVERY_TICKET_SCANNED_ON = 'Delivery Ticket Scan',
    DELIVERY_VERIFIED_DATE = 'Verified',
    DELIVERY_ZIP = 'Delivery Zip',
    DELIVERY_ZONE = 'Delivery Zone',
    DOCUMENT = 'Document',
    EVAL_ACTUAL_DATE = 'Eval Actual',
    EVAL_ACTUAL_PERSON = 'Eval Actual Person',
    EVAL_ATTEMPTS = 'Eval Attempts',
    EVAL_CITY = 'Eval City',
    EVAL_SCHEDULED_DATE = 'Eval Scheduled',
    EVAL_ZIP = 'Eval Zip',
    EVAL_ZONE = 'Eval Zone',
    FACILITY = 'Facility',
    FINAL_EST_RECVD = 'Final Est Received',
    FINAL_EST_SHIP_DATE = 'Final Est Ship Date',
    FPNA_DATE = 'FPNA Date',
    IS_ALS = 'ALS',
    IS_CREDIT_EXCEPTION = 'Credit Exception',
    IS_CREDIT_EXCEPTION_DELIVERY = 'Credit Exception Delivery',
    IS_F2F_SCANNED = 'isFaceToFaceScanned',
    IS_IN_SHOP = 'In Shop',
    IS_ON_HOLD = 'On Hold',
    IS_WORKERS_COMP = 'Workers Comp',
    ORDER_TOTAL = 'Order Total',
    ORDER_TYPE = 'Order Type',
    PAST_DUE = 'Past Due',
    PAYER_NEAREST_EXPIRING_AUTH_EXPIRATION = 'Auth Exp',
    PAYER_ONE = 'Payer One',
    PAYER_AUTH_EXPIRED_DATE = 'Auth Expired Date',
    PAYER_THREE = 'Payer Three',
    PAYER_TWO = 'Payer Two',
    PHYSICIAN = 'Physician',
    POST_BILL = 'Post-Bill',
    PRE_BILL = 'Pre-Bill',
    PRIORITY = 'Priority',
    READY_TO_PROCESS_DATE = 'Ready to Process Date',
    REQUIREMENTS_CODE = "Req's",
    RESUBMIT_DATE = 'Resubmit Date',
    REVENUE = 'Revenue',
    RTS = 'RTS',
    SUBORDER_TYPE = 'Sub-Order Type',
    TASK_TYPE = 'Task Type',
    THERAPIST = 'Therapist',
    TIMELY_FILING_DATE = 'Timely Filing Date',
    TOTAL_ATTEMPTS = 'Total Attempts',
    VERIFIED = 'Verified',
    WORK_ORDER_DATE = 'Work Order Date',
    WORK_ORDER_NUMBER = 'Work Order Num.',
    ALL_PO_RECVD = 'All POs Received',
    NEEDS_REVIEW = 'Validation Required',
    MIR_TYPE = 'Mir Type',
    MIR_SUBTYPE = 'Mir Sub Type',
    MIR_PAYER = 'Mir Payer'
}

export enum SortDir {
    ASC = 'asc',
    DESC = 'desc'
}

export enum BillingEnum {
    PRE_BILL = 'preBilling',
    POST_BILL = 'postBilling'
}
