import { PayerBranchDto } from '@app/universal-payers-database/models/dto';
import * as moment from 'moment';
import { MirDetails } from '../funding-specialist/models';
import { CustomerTypeValues } from './customer.type';
import { DateFormats } from './date.formats';
import { ppqaAnswerResults } from './ppqaResults';

export enum DiagnosticCodes {
    ALS = 'G12.2'
}

export enum SecondLevelReviewState {
    IN_SECONDARY_REVIEW = 1,
    SENT_TO_FUNDING_SPECIALIST = 2,
    COMPLETED = 3,
    MANUALLY_ADDED = 4
}

/**
 * Represents a single Work Order in RA, this object
 * gets converted into a WorkTask which the UI uses.
 */
export class WorkOrder {
    id: number;
    allPurchaseOrdersIssued: Date;
    actualAssemblyDate: Date;
    branch: string;
    client: WorkOrderPatient;
    createdOn: Date;
    creditExceptionDate: Date;
    creditExceptionDelivery: Date;
    csr: WorkOrderPerson;
    customerType: CustomerType;
    delivery: Delivery;
    deliveryVerifiedOn: Date;
    evaluation: Evaluation;
    externalCandidate: boolean;
    facility: Facility;
    finalFundingApprovedDate: Date;
    fpnaCompleteDate: Date;
    isAllPurchaseOrdersIssued: boolean;
    isAllPurchaseOrdersReceived: boolean;
    isFaceToFaceScanned: boolean;
    isBenefitsChecked: boolean;
    isCreditException: boolean;
    isHighPriority: boolean;
    isInShop: boolean;
    isOnHold: boolean;
    tpsPerformanceGroupID: number;
    mirDetails: MirDetails;
    orderType: OrderType;
    payers: Array<Payer>;
    physician: WorkOrderPerson;
    primaryInsurance: string;
    priority: number;
    productType: ProductType;
    readyToProcessDate: Date;
    revenueType: string;
    rts: WorkOrderPerson;
    salesOrder: SalesOrder;
    scheduledDeliveryOn: Date;
    secondLevelReviewState: SecondLevelReviewState;
    subOrderType: OrderType;
    therapist: WorkOrderPerson;
    totalAmount: number;
    workOrderNumber: string;
    keyAcctManagerName: string;
    actionRequired: ActionRequired;
    ppqaAnswerResults?: ppqaAnswerResults;
    constructor() {}

    hasPayers(): boolean {
        return this.payers && this.payers.length > 0;
    }

    isExternalCandidate(): boolean {
        return this.externalCandidate;
    }

    getFacilityName(): string {
        if (this.facility) {
            return this.facility.name;
        }
        return null;
    }

    getCustomerDescription(): string {
        if (this.customerType) {
            return this.customerType.description;
        }
        return null;
    }

    getOrderDescription(): string {
        if (this.orderType) {
            return this.orderType.description;
        }
        return null;
    }

    getSubOrderDescription(): string {
        if (this.subOrderType) {
            return this.subOrderType.description;
        }
        return null;
    }

    getClientPrimaryPhone(): string {
        if (this.client) {
            return this.client.getHomePhone();
        }
        return null;
    }

    getClientCellPhone(): string {
        if (this.client) {
            return this.client.getCellPhone();
        }
        return null;
    }

    getClientName(): string {
        if (this.client) {
            return this.client.getName();
        }
        return null;
    }

    getPhysicianName(): string {
        if (this.physician) {
            return this.physician.getName();
        }
        return null;
    }

    getTherapistName(): string {
        if (this.therapist) {
            return this.therapist.getName();
        }
        return null;
    }

    getCsrName(): string {
        if (this.csr) {
            const csrName = `${this.csr.firstName} ${this.csr.lastName}`;
            return csrName.trim();
        }
        return null;
    }

    getRtsName(): string {
        if (this.rts) {
            return this.rts.getName();
        }
        return null;
    }

    getPayerOneName(): string {
        return this.getPayerName(0);
    }
    getPayerOneCode(): string {
        return this.getPayerCode(0);
    }
    getPayerOnePlanNumber(): string {
        return this.getPayerPlanNumber(0);
    }

    getPayerOneGroupNumber(): string {
        return this.getPayerGroupNumber(0);
    }

    getPayerOneAuthExpiredDate(): Date {
        const payer = this.getPayer(0);
        if (payer && payer.authorizationExpiredDate) {
            return moment(payer.authorizationExpiredDate).toDate();
        }
    }

    getPayerOneRequiredDocs(): Document[] {
        return this.getPayerRequiredDocs(0);
    }

    getPayerTwoName(): string {
        return this.getPayerName(1);
    }
    getPayerTwoCode(): string {
        return this.getPayerCode(1);
    }
    getPayerTwoPlanNumber(): string {
        return this.getPayerPlanNumber(1);
    }

    getPayerTwoGroupNumber(): string {
        return this.getPayerGroupNumber(1);
    }

    getPayerTwoAuthExpiredDate(): Date {
        const payer = this.getPayer(1);
        if (payer && payer.authorizationExpiredDate) {
            return moment(payer.authorizationExpiredDate).toDate();
        }
    }
    getPayerTwoRequiredDocs(): Document[] {
        return this.getPayerRequiredDocs(1);
    }

    getPayerThreeName(): string {
        return this.getPayerName(2);
    }
    getPayerThreeCode(): string {
        return this.getPayerCode(2);
    }
    getPayerThreePlanNumber(): string {
        return this.getPayerPlanNumber(2);
    }

    getPayerThreeGroupNumber(): string {
        return this.getPayerGroupNumber(2);
    }

    getPayerThreeAuthExpiredDate(): Date {
        const payer = this.getPayer(2);
        if (payer && payer.authorizationExpiredDate) {
            return moment(payer.authorizationExpiredDate).toDate();
        }
    }
    getPayerThreeRequiredDocs(): Document[] {
        return this.getPayerRequiredDocs(2);
    }
    getDocumentIsValidated(docName: string): boolean {
        var rtn: boolean;
        this.payers.forEach(payer => {
            var doc = payer.requiredDocuments.find(f => f.type === docName);
            if (doc) {
                if (doc.needsValidated && doc.completed === true && doc.validatedDocIsValidated === false) {
                    rtn = false;
                }
            }
        });
        return rtn;
    }

    getDaysOpen(): number {
        const a = moment(this.createdOn);
        const b = moment(new Date());
        return b.diff(a, 'days');
    }

    isALS(): boolean {
        return (
            this.client.diagCodes &&
            this.client.diagCodes.find((dc: DiagnosticCodes) => {
                // ALS codes begin with this code.
                return dc.toLowerCase().startsWith(DiagnosticCodes.ALS.toLowerCase());
            }) != null
        );
    }

    isWorkersComp(): boolean {
        return this.customerType.description === CustomerTypeValues.WORKERS_COMP;
    }

    isExpedited(): boolean {
        return this.customerType.description === CustomerTypeValues.EXPEDITED;
    }

    private getPayerName(index: number): string {
        const payer = this.payers[index];
        if (this.hasPayers() && payer) {
            return payer.name;
        }
        return null;
    }
    private getPayerCode(index: number): string {
        const payer = this.payers[index];
        if (this.hasPayers() && payer) {
            return payer.payerCode;
        }
        return null;
    }
    private getPayerRequiredDocs(index: number): Document[] {
        const payer = this.payers[index];
        if (this.hasPayers() && payer) {
            return payer.requiredDocuments;
        }
        return null;
    }
    private getPayerPlanNumber(index: number) {
        const payer = this.payers[index];
        if (this.hasPayers() && payer) {
            return payer.planNumber;
        }
        return null;
    }

    private getPayerGroupNumber(index: number) {
        const payer = this.payers[index];
        if (this.hasPayers() && payer) {
            return payer.groupNumber;
        }
        return null;
    }

    private getPayer(index: number): Payer {
        const payer = this.payers[index];
        if (this.hasPayers() && payer) {
            return payer;
        }
        return null;
    }
}

export class Payer {
    payerId: number;
    payerCode: string;
    name: string;
    requiredDocuments: Array<Document>;
    phone: string;
    authorizationExpiredDate: Date;
    planNumber: string;
    groupNumber: string;
}

export class Document {
    id: number;
    type: string;
    completed: boolean;
    updNote: string;
    raTypeName: string;
    validatedDocIsValidated: boolean;
    needsValidated: boolean;
}

export class WorkOrderPerson {
    id: number;
    personId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    dateOfBirth: Date;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    zip: string;
    zone: string;
    fullAddress: string;
    diagCodes: Array<DiagnosticCodes> = [];

    getName(): string {
        const name = this.firstName + ' ' + this.lastName;
        return name.trim();
    }

    getPhone(): string {
        return this.phone;
    }

    getDateOfBirth(): string {
        if (this.dateOfBirth) {
            return moment(this.dateOfBirth).format(DateFormats.MM_DD_YYYY_DASH);
        } else {
            return '';
        }
    }
}

export class WorkOrderPatient extends WorkOrderPerson {
    homePhone: string;
    cellPhone: string;

    getHomePhone(): string {
        return this.homePhone;
    }

    getCellPhone(): string {
        return this.cellPhone;
    }
}

export class CustomerType {
    customerTypeId: number;
    description: CustomerTypeValues;
}

export class OrderType {
    id: number;
    orderTypeId: number;
    description: string;
}

export class Facility {
    facilityId: string;
    name: string;
    phone: string;
    email: string;
}

export class Delivery {
    deliveredByPrimary: WorkOrderPerson;
    deliveredBySecondary: WorkOrderPerson;
    deliverySchedulingAttempts: number;
    lastDeliverySchedulingAttempt: Date;
    scheduledOn: Date;
    deliveredOn: Date;
    deliveryZone: string;
    deliveryCity: string;
    deliveryZipCode: string;
    ticketScanned: boolean;
    ticketScannedOn: Date;
    verified: boolean;
    requirementsCode: string;
    actualAssemblyDate: Date;
    isAssemblyComplete: boolean;
}

export class Evaluation {
    evaluationCity: string;
    evaluationZipCode: string;
    evaluationPerson: string;
    scheduledPerson: string;
    scheduledOn: Date;
    evaluatedOn: Date;
    lastEvaluationSchedulingAttempt: Date;
    evaluationSchedulingAttempts: number;
    evaluationZone: string;
}

export class ProductType {
    name: string;
    marginThresholdPercentage: number;
}

export class SalesOrder {
    branchReceived: Date;
    finalEstimatedShipDate: Date;
    finalEstimateReceived: Date;
}

export interface ActionRequired {
    actionRequiredDate: Date;
    actioneRequired_name: string;
    actionRequired_Attempts: number;
    actionRequiredType: string;
    actionRequiredNotes: string;
}
