// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//chris
// `.env.ts` is generated by the `npm run env` command
import env from './.env';

const rehabAdvisorUrl: string = 'http://rehabtest';
//const rehabAdvisorUrl: string = 'https://10.0.0.30';

export const gatewayBaseUrl: string = 'https://nsmfundingportalapidev.azure-api.net';
const updBaseUrl: string = 'https://nsmfundingportalapidev.azure-api.net';

//export const apiWorkQueueBaseUrl = 'https://localhost:5002';
export const apiWorkQueueBaseUrl: string = gatewayBaseUrl + '/workqapi';
export const apiWorkOrderBaseUrl: string = gatewayBaseUrl + '/workorderapi';
//export const apiWorkOrderBaseUrl: string = 'https://localhost:5005';

export const featureConfigBaseUrl: string = gatewayBaseUrl + '/featureconfigapi/v1/featuresconfig';
export const updApiBaseUrl: string = updBaseUrl + '/upd';
//export const updApiBaseUrl: string = 'https://localhost:50785';
export const dmsApiBaseUrl: string = gatewayBaseUrl + '/nsmdmsapi/v2';
//export const dmsApiBaseUrl: string = 'https://localhost:53514/v2';

export const dmsApiWorkOrderBaseUrl: string = gatewayBaseUrl + '/dynamicsapi/v1';
export const sbBaseUrl: string = 'https://appsvc-sb-api-dev.azurewebsites.net';
export const fpBlobStorageBaseUrl: string = 'https://nsmfunctionstoragedev.blob.core.windows.net';
export const localDevApi: string = 'https://localhost:5001';
export const environment = {
    development: true,
    production: false,
    version: env.npm_package_version + '-dev',
    serverUrl: '/api',
    defaultLanguage: 'en-US',
    supportedLanguages: ['en-US', 'fr-FR'],
    allowImpersonation: true,
    signalrApiUrl: 'https://fapp-fp-signalr-dev.azurewebsites.net/api',
    dmsSignalRApiUrl: 'https://fapp-dmsevents-signalr-dev.azurewebsites.net/api',
    updSignalRApiUrl: 'https://fapp-upd-processing-dev.azurewebsites.net/api',
    apiWorkQueueBaseUrl: apiWorkQueueBaseUrl,
    apiGetWorkQueueItems: apiWorkQueueBaseUrl + '/v1/WorkItems',
    apiGetQueues: apiWorkQueueBaseUrl + '/v2/Queues',
    apiGetWorkOrders: apiWorkOrderBaseUrl + '/v1/WorkOrders',
    apiWorkOrderBranches: apiWorkOrderBaseUrl + '/v1/Branches',
    apiGetWorkOrderNotes: apiWorkOrderBaseUrl + '/v1/Notes',
    apiGetWorkLoad: apiWorkQueueBaseUrl + '/v1/WorkLoad',
    //apiGetWorkLoad:  'https://localhost:44391/v1/WorkLoad',
    apiGetWorkTasks: gatewayBaseUrl + '/tasks',
    apiAdminReporting: `${apiWorkQueueBaseUrl}/v2/Report`,
    apiAdminReportJobs: `${apiWorkQueueBaseUrl}/v2/ReportJob`,
    rehabAdvisorWorkOrderBaseUrl: rehabAdvisorUrl + '/MainApp/_wo_review/default.cfm',
    rehabAdvisorWorkOrderBaseUrlChrome: rehabAdvisorUrl + '/MainApp/_wo_review/default.cfm',
    featureOptionBaseUrl: featureConfigBaseUrl + '/{featureName}',
    featureOption_DynamicsEnabled: 'DYNAMICS_API_ENABLED',
    featureOption_WorkersCompEnabled: 'WORKERS_COMP_ENABLED',
    featureOption_UpdEnabled: 'UPD_ENABLED',
    featureOption_AppMenuEnabled: 'APP_MENU_ENABLED',
    featureOption_CompleteMirEnabled: 'COMPLETE_MIR_ENABLED',
    featureOption_ReportingEnabled: 'REPORTING_ENABLED',
    featureOption_UpdPayersEnabled: 'UPD_PAYERS_ENABLED',
    featureOption_OtherRequiredDocsEnabled: 'OTHER_REQUIRED_DOCS_ENABLED',
    featureOption_UseCeDocTypes: 'USE_CE_DOCUMENT_TYPES',
    featureOption_UsePreDeliveryIcons: 'USE_PRE_DELIVERY_ICONS',
    featureOption_CsrQueuesEnabled: 'CSR_QUEUES_ENABLED',
    featureOption_SecondaryReview: 'SECONDARY_REVIEW_ENABLED',
    featureOption_ExpiringAuth: 'EXPIRING_AUTH_ENABLED',
    featureOption_DmsSendEmailFax: 'DMS_SEND_EMAILFAX',
    featureOption_ExpressEnabled: 'EXPRESS_PAYER_ENABLED',
    featureOption_ProviderNumberEnabled: 'PROVIDER_NUMBER_ENABLED',
    featureOption_FundingSpecialistResync: 'FUNDING_SPECIALIST_RESYNC_ENABLED',
    featureOption_PayerCredentialing: 'PAYER_CREDENTIALING',
    featureOption_BranchLicensing: 'BRANCH_LICENSING',
    featureOption_MedicareFeeScheduling: 'USE_CMS_MEDICARE_FORMAT',
    featureOption_ActionRequired: 'ACTION_REQUIRED',
    featureOption_UPDContracts_Enabled: 'UPD_CONTRACTS_ENABLED',
    featureOption_FS_ALLOWABLE_LINE_EDIT: 'FS_ALLOWABLE_LINE_EDIT',
    customerType_WorkersCompId: 9,
    azureAdClientId: '55676742-d6b2-4f01-9cc6-1c0b33bd7a8d',
    azureAdTenantId: 'ed907452-f178-4336-8e28-497e69d8aba4',
    azureAdAuthority: 'https://login.microsoftonline.com/nsm-seating.com',
    azureAdRedirectUrl: window.location.origin,
    atpApi: gatewayBaseUrl + '/resourceapi/v1/Atps',
    branchApi: gatewayBaseUrl + '/resourceapi/v1/Branches',
    customerTypesApi: gatewayBaseUrl + '/resourceapi/v1/CustomerTypes',
    facilityApi: gatewayBaseUrl + '/resourceapi/v1/Facilities',
    orderTypesApi: gatewayBaseUrl + '/resourceapi/v1/OrderTypes',
    subOrderTypesApi: gatewayBaseUrl + '/resourceapi/v1/SubOrderTypes',
    therapistApi: gatewayBaseUrl + '/resourceapi/v1/Therapists',
    userAuthorizationApi: gatewayBaseUrl + '/userauthorizationapi/v1/UserAuthorizations',
    //userAuthorizationApi:  'http://localhost:24789/v1/UserAuthorizations',
    csrGroupsApi: gatewayBaseUrl + '/userauthorizationapi/v1/CsrGroups',
    //csrGroupsApi: 'http://localhost:24789/v1/CsrGroups',

    payersApi: gatewayBaseUrl + '/payersapi/v1/Payers',
    //rehabNotesApi: gatewayBaseUrl + 'https://localhost:5001/v1/Notes',
    rehabNotesApi: gatewayBaseUrl + '/rehabapi/v1/Notes',
    mirRecordsApi: gatewayBaseUrl + '/rehabapi/v1/MirRecords',
    workOrderSearchApi: gatewayBaseUrl + '/rehabapi/v1/WorkOrder',
    dynamicsApiUrlWorkOrders: gatewayBaseUrl + '/dynamicsapi/v1/WorkOrder',
    dynamicsApiUrlNotes: gatewayBaseUrl + '/dynamicsapi/v1/Notes',
    dynamicsApiUrlProductTypes: gatewayBaseUrl + '/dynamicsapi/v1/ProductType',
    dynamicsApiUrlOrderTypes: gatewayBaseUrl + '/dynamicsapi/v1/OrderType',
    dynamicsApiUrlFacilities: gatewayBaseUrl + '/dynamicsapi/v1/Facility',
    dynamicsApiUrlAtps: gatewayBaseUrl + '/dynamicsapi/v1/Atp',
    dynamicsApiUrlTherapists: gatewayBaseUrl + '/dynamicsapi/v1/Therapist',
    dynamicsApiUrlPayers: gatewayBaseUrl + '/dynamicsapi/v1/Payer',
    dynamicsApiUrlBranches: gatewayBaseUrl + '/dynamicsapi/v1/Branch',
    dynamicsUrl: 'https://nsm-dev.crm.dynamics.com/main.aspx',
    dynamicsAppId: '103f200e-b02f-e911-a846-000d3a37207b',
    directorsGroupId: 'a943300d-cd47-4bd0-aeef-b06f22549eaa',
    specialistsGroupId: '723683f3-b095-44b0-ac92-5d0a79727a55',
    azureAppInsightsInstrumentationKey: '2b872f2d-ac33-4d01-be53-73b06701a161',
    updApiUrlGroups: updApiBaseUrl + '/v1/PayerGroups',
    updApiUrlPayers: updApiBaseUrl + '/v1/Payers',
    updApiUrlPayerCopy: updApiBaseUrl + '/v1/PayerCopy',
    updApiUrlContracts: updApiBaseUrl + '/v1/Contracts',
    updApiUrlFeeSchedules: updApiBaseUrl + '/v1/FeeSchedules',
    updApiUrlAllowables: updApiBaseUrl + '/v1/AllowableHcpcsCodes',
    updApiUrlFeeScheduleAudit: updApiBaseUrl + '/v1/FeeScheduleAudit',
    updApiUrlPayerAudit: updApiBaseUrl + '/v1/PayerAudit',
    updApiUrlContacts: updApiBaseUrl + '/v1/PayerContacts',
    updApiUrlBranches: updApiBaseUrl + '/v1/Branches',
    updApiUrlPayerBranches: updApiBaseUrl + '/v1/PayerBranches',
    updApiUrlPayerBranchCredentials: updApiBaseUrl + '/v1/Credentials',
    updApiUrlBranchLicensing: updApiBaseUrl + '/v1/BranchLicensing',
    updApiUrlLicenseStatus: updApiBaseUrl + '/v1/LicenseStatus',
    updApiUrlBillingProfile: updApiBaseUrl + '/v1/BillingProfiles',
    updApiUrlCollectionProfile: updApiBaseUrl + '/v1/CollectionsProfiles',
    updApiUrlPayerErCob: updApiBaseUrl + '/v1/ercob',
    updApiUrlPayerEligibility: updApiBaseUrl + '/v1/EligibilityAndAuths',
    updApiUrlPayerRentalsCoding: updApiBaseUrl + '/v1/CodingAndRentals',
    updApiUrlPayerClientResponsibility: updApiBaseUrl + '/v1/ClientResponsibilityProfiles',
    updApiUrlPayerRuleGroupBranches: updApiBaseUrl + '/v1/PayerRuleGroupBranches',
    updApiUrlEdi: updApiBaseUrl + '/v1/EdiProfiles',
    updApiUrlPayerNotes: updApiBaseUrl + '/v1/PayerNotes',
    updApiUrlGroupNotes: updApiBaseUrl + '/v1/GroupNotes',
    updApiUrlFeeScheduleNotes: updApiBaseUrl + '/v1/FeeScheduleNotes',
    updApiUrlClaimRequirements: updApiBaseUrl + '/v1/ClaimRequirements',
    updApiUrlLookup: updApiBaseUrl + '/v1/Lookup',
    updApiUrlIcdCodes: updApiBaseUrl + '/v1/IcdCodes',
    updApiUrlHcpcCodes: updApiBaseUrl + '/v1/HcpcCodes',
    updApiUrlStates: updApiBaseUrl + '/v1/States',
    updApiUrlCompBidPostalCodes: updApiBaseUrl + '/v1/CompetitiveBidPostalCodes',
    updApiUrlHcpcModifiers: updApiBaseUrl + '/v1/HcpcModifiers',
    updApiUrlRuralPostalCodes: updApiBaseUrl + '/v1/RuralPostalCodes',
    updApiUrlEdiPayerNames: updApiBaseUrl + '/v1/EdiPayerNames',
    updApiUrlPayerRollups: updApiBaseUrl + '/v1/PayerRollups',
    updApiUrlPlanTypeRollups: updApiBaseUrl + '/v1/PlanTypeRollups',
    updApiUrlBillingPlanTypeRollups: updApiBaseUrl + '/v1/BillingPlanTypeRollups',
    updApiUrlAuditLogs: updApiBaseUrl + '/v1/AuditLogs',
    updApiUrlReportExports: updApiBaseUrl + '/v1/ReportExports',
    updApiUrlDocumentCategories: updApiBaseUrl + '/v1/DocumentCategories',
    updDiscrepancyFormEmailTo: 'david.cooper@ronin.consulting',
    dmsApiUrlDocument: dmsApiBaseUrl + '/document',
    dmsApiUrlCommunication: dmsApiBaseUrl + '/communication',
    dmsApiUrlUpd: dmsApiBaseUrl + '/upd',
    dmsApiUrlNotification: dmsApiBaseUrl + '/notification',
    dmsAzureDmsUrl: 'https://stordmsdev.blob.core.windows.net',
    dmsApiSearchWorkOrders: dmsApiWorkOrderBaseUrl + '/workorder',
    dmsApiCe: dmsApiBaseUrl + '/ce',
    sbWorkOrder: `${sbBaseUrl}/v1/workOrder`,
    sbExpectedReimbursment: `${gatewayBaseUrl}/upd/v1/er`,
    dmsApiUrlDocumentType: dmsApiBaseUrl + '/DocumentType',
    dmsApiBranchEmailFax: dmsApiBaseUrl + '/BranchEmailFax',
    rehabLookupApi: gatewayBaseUrl + '/rehabapi/v1/Lookup',
    rehabEmployeeApi: gatewayBaseUrl + '/rehabapi/v1/Employee',
    sbApiVendors: sbBaseUrl + '/v1/Vendors',
    rehabApi: gatewayBaseUrl + '/rehabapi/v1',
    cfApiDelete: 'https://cfapi-dev.nsm-seating.com/proxy/docDelete',
    cfApiInsert: 'https://cfapi-dev.nsm-seating.com/proxy/docInsert',
    cfRelayKey: '5C5ED665-C92E-4603-BF66-C88B2B961EA9'
};
