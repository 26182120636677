import { WorkOrder } from './work.order';
import { WorkItem } from './work.item';
import * as moment from 'moment';
import { DateFormats } from './date.formats';
import { BillingEnum } from './filter.models';
import { formatDate } from '@angular/common';
import { ppqaAnswerResults } from './ppqaResults';

/**
 * Base class for Work Items.
 */
export class WorkTask {
    allPurchaseOrdersIssued: Date;
    branch: string;
    client: string;
    clientCity: string;
    clientCellPhone: string;
    clientHomePhone: string;
    clientZip: string;
    clientZone: string;
    customerType: string;
    daysInReport: number;
    daysPastDue: number;
    daysSinceCreation: number;
    dueOn: Date;
    facility: string;
    isAllPurchaseOrdersReceived: boolean;
    isALS: boolean;
    isAssemblyComplete: boolean;
    isBenefitsChecked: boolean;
    isCreditException: boolean;
    isCreditExceptionDelivery: boolean;
    isExpiringAuth: boolean;
    isExpiredAuth: boolean;
    isFaceToFaceScanned: boolean;
    isExpedited: boolean;
    isFinalFunding: boolean;
    isFuture: boolean;
    isHighPriority: boolean;
    isInShop: boolean;
    isOnHold: boolean;
    isPastDue: boolean;
    isWorkersComp: boolean;
    tpsPerformanceGroupID: number;
    orderTotalAmount: number;
    orderType: string;
    actionStage: string;
    physician: string;
    priority: number;
    productType: string;
    rehabTechnologySupplier: string;
    subOrderType: string;
    taskType: string;
    therapist: string;
    totalAttempts: number;
    workItem: WorkItem;
    workOrder: WorkOrder;
    workOrderDate: Date;
    workOrderNumber: string;
    keyAcctManagerName: string;
    isLoading: boolean;
    payerOnePlanNumber?: string;
    payerOneGroupNumber?: string;
    payerTwoPlanNumber?: string;
    payerTwoGroupNumber?: string;
    payerThreePlanNumber?: string;
    payerThreeGroupNumber?: string;
    orderTypeId: string;
    isValidated?: boolean;
    ppqaAnswerResults?: ppqaAnswerResults;

    constructor() {}

    containsValue(value: string): boolean {
        const keys: Array<string> = Object.keys(this);
        const lowerCaseValue: string = value ? value.toLowerCase() : '';
        let hasValue: boolean = false;
        for (let i = 0, max = keys.length; i < max; i++) {
            const propertyValue = this[keys[i]];
            if (
                propertyValue &&
                propertyValue
                    .toString()
                    .toLowerCase()
                    .indexOf(lowerCaseValue) > -1
            ) {
                hasValue = true;
                break;
            }
        }
        return hasValue;
    }

    hasDocumentType(type: string): boolean {
        if (this instanceof DocumentRequirementWorkTask) {
            return this.workItem.description === type;
        }
        return false;
    }

    hasOrderType(orderTypeName: string): boolean {
        return this.orderType === orderTypeName;
    }

    hasActionStage(actionStageName: string): boolean {
        return this.actionStage === actionStageName;
    }
    hasSubOrderType(subOrderTypeName: string): boolean {
        return this.subOrderType === subOrderTypeName;
    }

    hasCustomerType(customerTypeName: string): boolean {
        return this.customerType === customerTypeName;
    }

    hasBranch(branch: string): boolean {
        return this.branch === branch;
    }

    hasRTS(name: string): boolean {
        return this.rehabTechnologySupplier === name;
    }

    hasFacility(name: string): boolean {
        return this.facility === name;
    }

    hasPhysician(name: string): boolean {
        return this.physician === name;
    }

    hasTherapist(name: string): boolean {
        return this.therapist === name;
    }

    hasKeyAcctManagerName(name: string): boolean {
        return this.workOrder.keyAcctManagerName === name;
    }

    findByPropertyName(propertyName: WorkItemPropertyNames): any {
        return this[propertyName];
    }

    shouldShowInQueue(): boolean {
        return true;
    }

    getFormattedDueDate(): string {
        return this.formatDate(this.workItem.dueOn);
    }

    getFormattedAuthExpirationDate(): string {
        if (this instanceof PendingAuthorizationWorkTask) {
            return this.formatDate(this.authExpirationDate);
        }
        return '(n/a)';
    }

    getFormattedFinalFundingDate(): string {
        return this.formatDate(this.workOrder.finalFundingApprovedDate);
    }

    getFormattedCreatedOnDate(): string {
        return this.formatDate(this.workItem.createdOn);
    }

    isDueToday(): boolean {
        return this.workItem.isDueToday();
    }

    getDateOfBirth(): string {
        if (this.workOrder && this.workOrder.client) {
            return this.workOrder.client.getDateOfBirth();
        } else {
            return '';
        }
    }

    private formatDate(date: Date): string {
        const md = moment(date);
        if (md.year() > 1900) {
            return moment(date)
                .startOf('day')
                .format(DateFormats.MM_DD_YYYY_DASH);
        } else {
            return null;
        }
    }
}

/**
 * Represents the view model used inside of the CSR queues.
 */
export class CsrWorkTask extends WorkTask {
    actualAssemblyDate: Date;
    branchReceived: Date;
    clientZone: string;
    csr: string;
    deliveryAttempts: number;
    deliveryCity: string;
    deliveryLastAttemptDate: Date;
    deliveryPerson: string;
    deliveryScheduledDate: Date;
    deliveryActualDate: Date;
    deliveryTicketScanned: boolean;
    deliveryTicketScannedOn: Date;
    deliveryVerifiedOn: Date;
    deliveryZip: string;
    deliveryZone: string;
    evaluationActualPerson: string;
    evaluationAttempts: number;
    evaluationCity: string;
    evaluationLastAttemptDate: Date;
    evaluationScheduledDate: Date;
    evaluationActualDate: Date;
    evaluationScheduledPerson: string;
    evaluationZip: string;
    evaluationZone: string;
    finalEstimatedShipDate: Date;
    finalEstimateReceived: Date;
    fpnaDate: Date;
    isAllPurchaseOrdersIssued: boolean;
    isCreditExceptionDelivery: boolean;
    isFaceToFaceScanned: boolean;
    payerNearestAuthExpDate: Date;
    payer: string;
    payerAuthExpiredDate: Date;
    payerTwo: string;
    payerTwoAuthExpiredDate: Date;
    payerThree: string;
    payerThreeAuthExpiredDate: Date;
    primaryInsurance: string;
    readyRate: string;
    readyToProcessDate: Date;
    requirementsCode: string;
    verified: Date;

    constructor() {
        super();
    }

    hasPayer(payerName: string): boolean {
        if (this.payer === payerName || this.payerTwo === payerName || this.payerThree) {
            return true;
        }
        return false;
    }

    hasClientZone(zone: string): boolean {
        return this.clientZone === zone;
    }

    hasEvalZone(zone: string): boolean {
        return this.evaluationZone === zone;
    }

    hasDeliveryZone(zone: string): boolean {
        return this.deliveryZone === zone;
    }

    hasCsr(name: string): boolean {
        return this.csr === name;
    }
}

/**
 * Represents the view model used inside of the FPNA queue.
 */
export class FpnaWorkTask extends WorkTask {
    payer: string;
    payerTwo: string;
    payerThree: string;
    constructor() {
        super();
    }

    hasPayer(payerName: string): boolean {
        if (this.payer === payerName || this.payerTwo === payerName || this.payerThree) {
            return true;
        }
        return false;
    }
}

/**
 * Represents the view model used inside of the Document Requirements queue.
 */
export class DocumentRequirementWorkTask extends WorkTask {
    name: string;
    label: string;
    payerCode: string;
    updNotes: string = '';
    payerIndex: number;
    payer: string;
    payerTwo: string;
    payerThree: string;
    isPayerOneDR: boolean = false;
    payerOneDRNote: string = '';

    isPayerTwoDR: boolean = false;
    payerTwoDRNote: string = '';

    isPayerThreeDR: boolean = false;
    payerThreeDRNote: string = '';
    constructor() {
        super();
    }
    get payerOneDRNoteDisplay(): string {
        return this.payer + ' - ' + (this.payerOneDRNote === '' ? 'No note.' : this.payerOneDRNote);
    }
    get payerTwoDRNoteDisplay(): string {
        return this.payerTwo + ' - ' + (this.payerTwoDRNote === '' ? 'No note.' : this.payerTwoDRNote);
    }
    get payerThreeDRNoteDisplay(): string {
        return this.payerThree + ' - ' + (this.payerThreeDRNote === '' ? 'No note.' : this.payerThreeDRNote);
    }

    isDeliveryDocument(): boolean {
        return this.taskType && this.taskType.toLowerCase() === DocumentRequirementType.DELIVERY.toLowerCase();
    }
    isSubmissionDocument(): boolean {
        return this.taskType && this.taskType.toLowerCase() === DocumentRequirementType.SUBMISSION.toLowerCase();
    }
    isPreDeliveryDocument(): boolean {
        return this.taskType && this.taskType.toLowerCase() === DocumentRequirementType.PREDELIVERY.toLowerCase();
    }

    formatLabel(): string {
        let parts = new Array<string>(),
            result = '';
        if (!this.label) return '';

        parts = this.label.split('|');
        if (parts.length > 0 && parts[0].indexOf('Other') > -1) {
            result = parts[0].replace('ther', ''); // Other1 => O1
            if (parts[1] !== '') result += ' ' + parts[1];
            //else result += ' Description unavailable';
            if (this.payerIndex) result += ' P' + this.payerIndex.toString();
        }
        return result;
    }
}

/**
 * Represents the view model used inside of the Ready to Submit queue.
 */
export class ReadyToSubmitWorkTask extends WorkTask {
    payer: string;
}
/**
 * Represents the view model used inside of the Ready to Submit queue.
 */
export class ActionWorkTask extends WorkTask {
    actionNote: string;
    actionStage: string;
    payer: string;
    payerTwo: string;
    payerThree: string;
}
/**
 * Represents the view model used inside of the Pending Authorization queue.
 */
export class PendingAuthorizationWorkTask extends WorkTask {
    payer: string;
    deferredDate: Date;
    resubmitDate: Date;
    authExpirationDate: Date;

    shouldShowInQueue(): boolean {
        return true;
    }
}

/**
 * Represents the view model used inside of the PPQA  queue.
 */
export class PpqaWorkTask extends WorkTask {
    payer: string;
    payerTwo: string;
    payerThree: string;
}

/**
 * Represents the view model used inside of the Delivery Verification queue.
 */
export class DeliveryVerificationWorkTask extends WorkTask {
    payer: string;
    payerTwo: string;
    payerThree: string;
    deliveryScheduledDate: Date;
}

/**
 * Represets the view model used in the MIR Queue.
 */
export class MirWorkTask extends WorkTask {
    mirType: any;
    mirPayer: string;
    planNumber: string;
    groupNumber: string;
    revenue: string;
    approvedDate: Date;
    timelyFiling: Date;
    postBill: boolean;
    preBill: boolean;
    amountUnbilled: number;
    billingContact: any;
    clarifyingNotes: string;
    mirSubType: string;
    constructor() {
        super();
    }

    hasMirSubType(subtype: string): boolean {
        return this.mirSubType === subtype;
    }

    hasMirType(mirType: string): boolean {
        return this.mirType === mirType;
    }

    hasBilling(billingtype: string): boolean {
        if (billingtype === BillingEnum.POST_BILL) return this.postBill === true;
        if (billingtype == BillingEnum.PRE_BILL) return this.preBill === true;
    }

    hasRevenue(revenue: string): boolean {
        return this.revenue === revenue;
    }

    hasTimelyFilingDate(filingDate: Date): boolean {
        let inputDate = moment(filingDate);
        let listDate = moment(this.timelyFiling);
        let input = inputDate.format(DateFormats.MMMM_DD_YYYY_SPACE);
        let list = listDate.format(DateFormats.MMMM_DD_YYYY_SPACE);
        return input === list;
    }

    amountUnbilledWithin(groupAmount: string): boolean {
        if (groupAmount === unbilledGroupType.under500) return this.amountUnbilled >= 0 && this.amountUnbilled <= 500;
        if (groupAmount === unbilledGroupType.fiveFive)
            return this.amountUnbilled >= 500 && this.amountUnbilled <= 5000;
        if (groupAmount === unbilledGroupType.fiveTen)
            return this.amountUnbilled >= 5000 && this.amountUnbilled <= 10000;
        if (groupAmount === unbilledGroupType.aboveTen) return this.amountUnbilled >= 10000;
    }

    hasMirPayer(name: string): boolean {
        return this.mirPayer === name;
    }
}

export class PropertyDecriptor {
    Name: string;
    DisplayName: string;
}
export enum WorkItemPropertyNames {
    IS_ALL_PO_RECEIVED = 'isAllPurchaseOrdersReceived',
    ACTION_STAGE = 'actionStage',
    ALL_PO_ISSUED = 'allPurchaseOrdersIssued',
    ALS_DIAGNOSIS = 'alsDiagnosis',
    AMOUNT_UNBILLED = 'amountUnbilled',
    ASSEMBLED_ON = 'actualAssemblyDate',
    BENEFITS_CHECKED = 'isBenefitsChecked',
    BILLING_CONTACT = 'billingContact',
    BRANCH = 'branch',
    BRANCH_RECVD = 'branchReceived',
    CLIENT = 'client',
    CLIENT_CELL_PHONE = 'clientCellPhone',
    CLIENT_CITY = 'clientCity',
    CLIENT_HOME_PHONE = 'clientHomePhone',
    CLIENT_ZIP = 'clientZip',
    CLIENT_ZONE = 'clientZone',
    CSR = 'CSR',
    CUSTOMER_TYPE = 'customerType',
    DAYS_IN_REPORT = 'daysInReport',
    DAYS_OPEN = 'daysOpen',
    DAYS_SINCE_CREATION = 'daysSinceCreation',
    DEFERRED_DATE = 'deferredDate',
    DELIVERY_ACTUAL_DATE = 'deliveryActualDate',
    DELIVERY_ATTEMPTS = 'deliveryAttempts',
    DELIVERY_CITY = 'deliveryCity',
    DELIVERY_LAST_ATTEMPT_DATE = 'deliveryLastAttemptDate',
    DELIVERY_PERSON = 'deliveryPerson',
    DELIVERY_SCHEDULED_DATE = 'deliveryScheduledDate',
    DELIVERY_TICKET_SCANNED = 'deliveryTicketScanned',
    DELIVERY_TICKET_SCANNED_ON = 'deliveryTicketScannedOn',
    DELIVERY_VERIFIED_DATE = 'deliveryVerifiedOn',
    DELIVERY_ZIP = 'deliveryZip',
    DELIVERY_ZONE = 'deliveryZone',
    DUE_ON = 'dueOn',
    EVAL_ACTUAL_DATE = 'evalActualDate',
    EVAL_ACTUAL_PERSON = 'evaluationActualPerson',
    EVAL_ATTEMPTS = 'evaluationAttempts',
    EVAL_CITY = 'evaluationCity',
    EVAL_LAST_ATTEMPT_DATE = 'evaluationLastAttemptDate',
    EVAL_SCHEDULED_DATE = 'evalScheduledDate',
    EVAL_SCHEDULED_PERSON = 'evaluationScheduledPerson',
    EVAL_ZIP = 'evaluationZip',
    EVAL_ZONE = 'evaluationZone',
    FACILITY = 'facility',
    FINAL_EST_RECVD = 'finalEstimateReceived',
    FINAL_EST_SHIP_DATE = 'finalEstimatedShipDate',
    FOLLOW_UP_DATE = 'followUpDate',
    FPNA_DATE = 'fpnaDate',
    IS_ALS = 'isALS',
    IS_ASSEMBLED = 'isAssemblyComplete',
    IS_CREDIT_EXCEPTION = 'isCreditException',
    IS_CREDIT_EXCEPTION_DELIVERY = 'isCreditExceptionDelivery',
    IS_EXPEDITED = 'isExpedited',
    IS_F2F_SCANNED = 'isFaceToFaceScanned',
    IS_FINAL_FUNDING_APPROVAL = 'isFinalFunding',
    IS_FUTURE = 'isFuture',
    IS_IN_SHOP = 'isInShop',
    IS_ON_HOLD = 'isOnHold',
    IS_PAST_DUE = 'isPastDue',
    IS_PRIORITY = 'isHighPriority',
    IS_WORKERS_COMP = 'isWorkersComp',
    KEY_ACCOUNT_MANAGER = 'keyAcctManagerName',
    MIR_TYPE = 'mirType',
    MIR_SUBTYPE = 'mirSubType',
    MIR_PAYER = 'mirPayer',
    MIR_PAYER_PLAN_NUMBER = 'planNumber',
    MIR_PAYER_GROUP_NUMBER = 'groupNumber',
    NAME = 'name',
    NUMBER_OF_ATTEMPTS = 'numberOfAttempts',
    ORDER_TOTAL = 'orderTotalAmount',
    ORDER_TYPE = 'orderType',

    PAST_DUE = 'daysPastDue',
    PAYER_NEAREST_EXPIRING_AUTH_EXPIRATION = 'payerNearestAuthExpDate',
    PAYER = 'payer',
    PAYER_TWO = 'payerTwo',
    PAYER_THREE = 'payerThree',
    PAYER_ONE_GROUP_NUMBER = 'payerOneGroupNumber',
    PAYER_TWO_GROUP_NUMBER = 'payerTwoGroupNumber',
    PAYER_THREE_GROUP_NUMBER = 'payerThreeGroupNumber',
    PAYER_ONE_PLAN_NUMBER = 'payerOnePlanNumber',
    PAYER_TWO_PLAN_NUMBER = 'payerTwoPlanNumber',
    PAYER_THREE_PLAN_NUMBER = 'payerThreePlanNumber',
    PAYER_AUTH_EXPIRED_DATE = 'payerAuthExpiredDate',
    PAYER_TWO_AUTH_EXPIRED_DATE = 'payerTwoAuthExpiredDate',
    PAYER_THREE_AUTH_EXPIRED_DATE = 'payerThreeAuthExpiredDate',
    PERF_DATE = 'perfDate',
    PHYSICIAN = 'physician',
    POST_BILL = 'isPostBill',
    PRE_BILL = 'isPreBill',
    PRIMARY_INSURANCE = 'primaryInsurance',
    PRIORITY = 'priority',
    PROCESSOR = 'processor',
    PRODUCT_TYPE = 'productType',
    READY_RATE = 'readyRate',
    READY_TO_PROCESS_DATE = 'readyToProcessDate',
    REHAB_TECHNOLOGY_SUPPLIER = 'rehabTechnologySupplier',
    REQUIREMENTS_CODE = 'requirementsCode',
    RESUBMIT_DATE = 'resubmitDate',
    REVENUE = 'revenue',
    RULES_COMPLETE_DATE = 'rulesComplete',
    SPECIFIC_NEEDS = 'specificNeeds',
    SUBMITTED_DATE = 'submittedDate',
    SUBORDER_TYPE = 'subOrderType',
    TASK_TYPE = 'taskType',
    THERAPIST = 'therapist',
    TIMELY_FILING_DATE = 'timelyFiling',
    TOTAL_ATTEMPTS = 'totalAttempts',
    VERIFIED_DATE = 'verifiedDate',
    WORK_ORDER_DATE = 'workOrderDate',
    WORK_ORDER_NUMBER = 'workOrderNumber',
    WORK_LOCATION = 'externalCandidate'
}

export enum DocumentRequirementType {
    SUBMISSION = 'REQUIRED_DOCUMENT',
    DELIVERY = 'DELIVERY_REQUIRED_DOCUMENT',
    PREDELIVERY = 'PRE_DELIVERY_REQUIRED_DOCUMENT',
    OPTIONAL = 'OPTIONAL'
}

export interface ProductTypeById {
    name: string;
    columnPrefix: string;
    ordertypeIdsorderTypeIds: number[];
}

export interface OtherDocumentsDetails {
    slot: number;
    description: string;
    timeFrame: string;
    notes: string;
}

export interface OtherRequiredDocDetails {
    payerCode: string;
    productLine: string;
    otherDocuments: OtherDocumentsDetails[];
}

export interface OtherRequiredDocsRequest {
    payerCode: string;
    productLine: string;
}

export enum MsdynWorkOrderTaskTypes {
    // FP queue task types
    DELIVERY_VERIFICATION = 'DELIVERY_VERIFICATION',
    // CSR queue task types
    DELIVERY_POSTING = 'DELIVERY_POSTING',
    DELIVERY_SCHEDULING = 'DELIVERY_SCHEDULING'
}

export class OrderTypeProductLineMapping {
    mapping: Map<number, string> = new Map<number, string>();
    constructor() {
        // not ideal, but better than going over the rehab API to the same hardcoded list
        [15, 53].forEach(x => {
            this.mapping.set(x, 'Complex Power');
        });
        [16, 52].forEach(x => {
            this.mapping.set(x, 'Complex Manual');
        });
        [2, 25, 57, 58].forEach(x => {
            this.mapping.set(x, 'Repairs');
        });
        [12, 20, 24, 59].forEach(x => {
            this.mapping.set(x, 'Mods');
        });
        [41, 55, 56].forEach(x => {
            this.mapping.set(x, 'ADL');
        });
        [14, 50].forEach(x => {
            this.mapping.set(x, 'Standard Manual');
        });
        [29].forEach(x => {
            this.mapping.set(x, 'Loaner');
        });
        [13, 26, 51, 54].forEach(x => {
            this.mapping.set(x, 'Standard Power And Scooter');
        });
        [65, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85].forEach(x => {
            this.mapping.set(x, 'Access');
        });
    }
}

export enum unbilledGroupType {
    under500 = '500',
    fiveFive = '5000',
    fiveTen = '10000',
    aboveTen = '10000+'
}
